import { Button, useToast } from "@chakra-ui/react";
import { trpc } from "../../../../utils/trpc";
import { showConfirmDialogue } from "../../../../components/AlertDialogue";
import { TRPCError } from "@trpc/server";
import { months } from "@timetable/server/src/utils/utils";

export function ReopenMonthButton({
  month_id,
  user_id,
  month_index,
  year,
}: {
  month_id: string;
  user_id: string;
  month_index: number;
  year: number;
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const { mutateAsync } = trpc.timesheetRouter.reopenMonth.useMutation({
    async onSuccess() {
      void utils.timesheetRouter.getDesktopMonth.invalidate({
        month: month_index,
        year: year,
      });
      await utils.adminRouter.getUserMonthOverview.invalidate({
        month_id: month_id,
        user_id: user_id,
      });
      toast({
        title: "Måned åpnet",
        status: "success",
      });
    },
    onError(error) {
      console.error(error);
      if (error instanceof TRPCError) {
        toast({
          title: "Noe gikk galt",
          description: error.message,
          status: "error",
        });
      } else {
        toast({
          title: "En ukjent feil har oppstått",
          description: String(error),
          status: "error",
        });
      }
    },
  });

  const month_name = `${months[month_index]} ${year}`;

  function HandleReopenMonth() {
    showConfirmDialogue({
      title: "Åpne måned",
      description: `Er du sikker på at du vil åpne ${month_name}? Brukeren vil kunne føre timer for ${month_name} igjen og godkjenning tilbakestilles.`,
      async onAccept() {
        await mutateAsync({ month_id: month_id });
      },
      variant: "confirm",
    });
  }

  return <Button onClick={HandleReopenMonth}>Åpne timeliste</Button>;
}

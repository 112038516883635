import { VStack, Text, Heading, Button, Link } from "@chakra-ui/react";
import { Logo } from "./Logo";
import { useState } from "react";

interface ErrorProps {
  errorData?: {
    error: Error;
    componentStack: string;
    eventId: string;
    resetError(): void;
  };
}

export const ErrorPage = (props: ErrorProps) => {
  const [show, setShow] = useState(false);
  return (
    <VStack w={"100%"} h={"100%"} margin={"auto"}>
      <Logo accentColor="#000000" />
      <Heading size={"md"}>Oops...</Heading>
      <Text>Her gikk noe galt.</Text>
      <Text>Feilen har blitt registrert og sendt til utvikleren.</Text>
      <Link href="https://app.timetable.no/">Tilbake til applikasjonen</Link>
      <Text as="i">
        Opplevd denne feilen flere ganger? Send en epost til
        <Link href="mailto:kontakt@kodeverkstedet.no ">
          {" "}
          kontakt@kodeverkstedet.no{" "}
        </Link>{" "}
        så hjelper vi deg.
      </Text>
      <Button onClick={() => setShow(true)}>Vis feilmelding</Button>
      <Text visibility={show ? "visible" : "hidden"}>
        {JSON.stringify(props.errorData?.error)}
      </Text>
    </VStack>
  );
};

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { getParamHref } from "@timetable/server/src/utils/Links";
import { trpc } from "utils/trpc";

interface FormInput {
  title: string;
}

export const InitAttachment = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { mutate } = trpc.bilagRouter.initAttachment.useMutation({
    onSuccess(data) {
      if (!data?.id) {
        throw new Error(
          "No attachment id returned, even though we has a success.",
        );
      }
      navigate(getParamHref("new_attachment", data.id));
    },
    onError(error) {
      toast({
        title: "Kunne ikke starte utlegg",
        description: String(error),
        status: "error",
      });
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    mutate(data);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button data-cy="init_button"> Nytt bilag</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Opprett bilag</PopoverHeader>
        <PopoverBody>
          <form id="title_form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.title ? true : false}>
              <FormLabel>Navn på bilag</FormLabel>
              <Input
                data-cy="title_field"
                {...register("title", {
                  required: "Du må ha et navn på bilaget.",
                })}
              ></Input>
              <FormHelperText>
                Dette blir brukt til å finne igjen bilaget senere.
              </FormHelperText>
              <FormErrorMessage>
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </PopoverBody>
        <PopoverFooter>
          <Button type="submit" form="title_form" data-cy="create_button">
            Opprett
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

import { useToast } from "@chakra-ui/react";
import { useSelectedMonth } from "../../../hooks/useSelectedMonth";
import { trpc } from "../../../utils/trpc";
import { months } from "../../../utils";
import { showConfirmDialogue } from "../../../components/AlertDialogue";

export function SendForApprovalButton({
  month_id,
  children,
}: {
  month_id: string;
  children: React.ReactNode;
}) {
  const { month, year } = useSelectedMonth();
  const utils = trpc.useUtils();
  const toast = useToast();
  const { mutateAsync } = trpc.timesheetRouter.sendMonthForApproval.useMutation(
    {
      async onSuccess() {
        await utils.timesheetRouter.getDesktopMonth.invalidate({
          month: month,
          year: year,
        });
        await utils.timesheetRouter.getMobileMonth.invalidate();
        toast({
          title: `${months[month]} ${year} sendt til godkjenning!`,
          status: "success",
        });
      },
    },
  );

  function sendMonthForApproval() {
    showConfirmDialogue({
      title: `Send ${months[month]} ${year} til godkjenning`,
      description: `Er du sikker på at du ønsker å sende ${months[month]} ${year} til godkjenning? Du vil ikke kunne føre flere timer for ${months[month]} ${year} etter denne handlingen.`,
      async onAccept() {
        await mutateAsync({ month_id });
      },
      variant: "confirm",
    });
  }

  return <div onClick={sendMonthForApproval}>{children}</div>;
}

import { Avatar, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { RouterOutputs } from "utils/trpc";
import { InviteActionMenu } from "components/InviteActionMenu";

export function InvitedEmployeeLine(props: {
  employee: RouterOutputs["adminRouter"]["getInvitedEmployees"][number];
}) {
  const { employee } = props;
  return (
    <HStack w={"100%"} justifyContent={"space-between"} py={2}>
      <HStack>
        <Avatar name={employee.email} size={{ base: "sm", md: "md" }} />
        <VStack gap={0} alignItems="start">
          <Text fontSize={"small"}>Invitasjon ({employee.given_role})</Text>
          <Text>{employee.email}</Text>
        </VStack>
      </HStack>
      <HStack>
        {employee.used ? (
          <Tag colorScheme="green">Brukt</Tag>
        ) : (
          <Tag colorScheme="orange">Ubrukt</Tag>
        )}
      </HStack>
      <InviteActionMenu
        inviteId={props.employee.inviteid}
        used={props.employee.used}
      />
    </HStack>
  );
}

import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { MidLoadingSpinner } from "components";
import { ModuleLayout } from "layout/ModuleLayout";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RouterInputs, RouterOutputs, trpc } from "utils/trpc";
import { CreateProjectSchema } from "@timetable/server/src/validation/zod/projects.js";

type FormType = RouterInputs["projectRouter"]["createProject"];

export function ProjectForm() {
  const utils = trpc.useUtils();
  const toast = useToast();
  const navigate = useNavigate();
  const { data: employees } = trpc.adminRouter.getEmployees.useQuery();
  const { mutate } = trpc.projectRouter.createProject.useMutation({
    onError() {
      toast({
        title: "Kunne ikke opprette prosjekt",
        status: "error",
      });
    },
    async onSuccess() {
      await utils.projectRouter.getProjects.invalidate();
      toast({
        title: "Prosjekt opprettet",
        status: "success",
      });
      navigate(-1);
    },
  });
  const form = useForm<FormType>({
    resolver: zodResolver(CreateProjectSchema),
  });

  function onSubmit(data: FormType) {
    mutate(data);
  }

  if (!employees) {
    return <MidLoadingSpinner />;
  }

  return (
    <ProjectFormElements
      users_in_organization={employees}
      form={form}
      onSubmit={onSubmit}
      formType="CREATE"
    />
  );
}

export function ProjectFormElements(props: {
  users_in_organization: NonNullable<
    RouterOutputs["adminRouter"]["getEmployees"]
  >;
  form: UseFormReturn<
    {
      name: string;
      users: string[];
    },
    any,
    undefined
  >;
  onSubmit: (data: { name: string; users: string[] }) => void;
  formType: "CREATE" | "UPDATE";
}) {
  return (
    <ModuleLayout
      pageTitle={
        props.formType === "CREATE" ? "Opprett prosjekt" : "Rediger prosjekt"
      }
    >
      <form onSubmit={props.form.handleSubmit(props.onSubmit)}>
        <VStack mt={5} gap={5} width={"full"}>
          <FormControl isInvalid={!!props.form.formState.errors.name}>
            <FormLabel>Prosjektnavn</FormLabel>
            <Input
              {...props.form.register("name")}
              type="text"
              aria-label="Navn på prosjekt"
            />
            <FormErrorMessage>
              {props.form.formState.errors.name &&
                props.form.formState.errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <HStack justifyContent={"space-between"} alignContent="center">
              <FormLabel>Medlemmer</FormLabel>
              <Button
                onClick={() =>
                  props.form.setValue(
                    "users",
                    props.users_in_organization.map((us) => us.id),
                    { shouldTouch: true },
                  )
                }
              >
                Velg alle
              </Button>
            </HStack>
            <Grid
              templateColumns={{
                sm: "auto",
                md: "repeat(2, auto)",
                lg: "repeat(3, auto)",
              }}
              templateRows={{ lg: "1" }}
            >
              {props.users_in_organization?.map((employee) => (
                <Controller
                  key={employee.id}
                  control={props.form.control}
                  name="users"
                  render={({ field }) => (
                    <GridItem key={employee.id}>
                      <Checkbox
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...(field.value || []), employee.id]
                            : field.value?.filter(
                                (v: string) => v !== employee.id,
                              ) || [];
                          field.onChange(newValue);
                        }}
                        value={employee.id}
                        isChecked={field.value?.includes(employee.id)}
                      >
                        {employee.first_name} {employee.last_name} (
                        {employee.email})
                      </Checkbox>
                    </GridItem>
                  )}
                ></Controller>
              ))}
            </Grid>
          </FormControl>
          <HStack w={"100%"} justifyContent={"end"}>
            <Button type="submit">Lagre endringer</Button>
          </HStack>
        </VStack>
      </form>
    </ModuleLayout>
  );
}

import {
  Container,
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Checkbox,
  Input,
  FormHelperText,
  Divider,
  FormErrorMessage,
  useToast,
  Select,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BaseForm } from "./BaseForm";
import { useNavigate, useParams } from "react-router-dom";
import { mod11SecurityDigitValid } from "utils";
import { MidLoadingSpinner } from "components";
import React from "react";
import { RouterOutputs, trpc } from "utils/trpc";
import { showConfirmDialogue } from "components/AlertDialogue";
import { SendAttachmentType } from "@timetable/server/src/validation/zod/bilag";
import { TRPCError } from "@trpc/server";
import * as Sentry from "@sentry/react";

export const CreateNewAttachment = () => {
  let { attachmentId } = useParams();
  if (!attachmentId)
    throw new Error("Attachmentid needs to be defined for this route.");
  const { data: entry } = trpc.bilagRouter.getSingleBilag.useQuery({
    attachmentId: attachmentId,
  });
  const { data: user_projects } = trpc.projectRouter.getUserProjects.useQuery();

  if (entry && user_projects) {
    return (
      <CreateNewAttachmentAsUser entry={entry} user_projects={user_projects} />
    );
  } else {
    return <MidLoadingSpinner />;
  }
};

function CreateNewAttachmentAsUser({
  entry,
  user_projects,
}: {
  entry: RouterOutputs["bilagRouter"]["getSingleBilag"];
  user_projects: RouterOutputs["projectRouter"]["getUserProjects"];
}) {
  const navigate = useNavigate();
  const toast = useToast();
  const { data: userProfile } = trpc.userRouter.getUserProfile.useQuery();
  const { data: files } = trpc.bilagRouter.getFilesInBucket.useQuery({
    attachment_id: entry.id,
  });
  const utils = trpc.useUtils();
  const { mutateAsync, isPending } = trpc.bilagRouter.sendBilag.useMutation({
    onSuccess(_, variables) {
      void utils.bilagRouter.getBilagDetail.invalidate({
        attachmentId: String(variables.attachment_id),
      });
      void utils.bilagRouter.getInteralBilag.invalidate();
      void utils.bilagRouter.getUserBilag.invalidate();
      toast({
        status: "success",
        title: "Utlegg sendt!",
      });
      navigate(-1);
    },
    onError(error) {
      Sentry.captureException(error);
      console.error(error);
      if (error instanceof TRPCError) {
        toast({
          status: "error",
          title: "Kunne ikke sende utlegg",
          description: error.message,
        });
      } else {
        toast({
          status: "error",
          title: "En ukjent feil oppsto",
          description: String(error),
        });
      }
    },
  });

  const { mutateAsync: saveMutate, isPending: saveIsPending } =
    trpc.bilagRouter.saveIncomplete.useMutation({
      onSuccess(_, variables) {
        void utils.bilagRouter.getUserBilag.invalidate();
        void utils.bilagRouter.getSingleBilag.invalidate({
          attachmentId: variables.attachment_id.toString(),
        });
        toast({
          status: "info",
          title: "Fremdrift lagret",
        });
      },
      onError(error) {
        console.error(error);
        if (error instanceof TRPCError) {
          toast({
            status: "error",
            title: "Kunne ikke lagre fremdrift.",
            description: error.message,
          });
        } else {
          toast({
            status: "error",
            title: "En ukjent feil oppsto",
            description: String(error),
          });
        }
      },
    });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SendAttachmentType>({
    defaultValues: {
      attachment_id: Number.parseInt(entry.id),
      organization_id: entry.company,
      title: entry.title ?? "",
      item: entry.item ?? "",
      description: entry.description ?? "",
      amount: entry.amount ?? 0,
      project_id: entry.project_id ?? undefined,
      account_number: entry.accountnumber ?? "",
      date_of_purchase: new Date(
        entry.date_of_purchase === null ? new Date() : entry.date_of_purchase,
      )
        .toISOString()
        .substring(0, 10),
    },
  });

  const onSubmit: SubmitHandler<SendAttachmentType> = async (formValues) => {
    if (!files) throw new Error("Unable to get files in bucket.");

    if (files.length === 0) {
      showConfirmDialogue({
        title: "Ingen vedlegg",
        description:
          "Du har ikke lastet opp noen vedlegg, ønsker du å fortsette?",
        variant: "confirm",
        async onAccept() {
          await saveMutate(formValues);
        },
      });
      return;
    }
    mutateAsync(formValues);
  };

  function handeSaveIncomplete() {
    const formState = getValues();
    if (Number.isNaN(formState.amount)) {
      formState.amount = 0;
    }
    saveMutate(formState);
  }

  async function fillOutAccountNumber(e: React.ChangeEvent<HTMLInputElement>) {
    if (userProfile && e.target.checked) {
      setValue(
        "account_number",
        userProfile.accountnumber === null ? "" : userProfile.accountnumber,
      );
    } else {
      setValue("account_number", "");
    }
  }

  return (
    <Container
      as={"form"}
      my={5}
      px={0}
      pb={10}
      onSubmit={handleSubmit(onSubmit)}
    >
      <VStack w={"100%"} alignItems={"flex-start"} gap={"5"}>
        <FormControl>
          <FormLabel>Tittel på utlegg</FormLabel>
          <Input {...register("title")} />
        </FormControl>
        <VStack w="100%" alignItems={"flex-start"} gap={"5"}>
          <Divider />

          <BaseForm
            register={register}
            attachmentId={entry.id}
            errors={errors}
          />
          <Divider />
          <FormControl>
            <FormLabel>Prosjekt</FormLabel>
            <Select
              {...register("project_id")}
              defaultValue={"no-project"}
              placeholder="Ingen prosjekt"
            >
              {user_projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Divider />
          <Heading size={"sm"}>Tilbakebetaling</Heading>
          <FormControl isInvalid={errors.account_number ? true : false}>
            <FormLabel w={"100%"}>
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Text>Kontonummer</Text>
                <Checkbox
                  data-cy="my_account_number"
                  onChange={fillOutAccountNumber}
                >
                  Mitt eget
                </Checkbox>
              </HStack>
            </FormLabel>

            <Input
              {...register("account_number", {
                required: "Du må ha et kontonummer",
                validate: {
                  validAccountNumber: (v) =>
                    mod11SecurityDigitValid(v, "ACCOUNT_NUMBER")
                      ? true
                      : "Kontonummer er ikke gyldig",
                },
              })}
            ></Input>
            <FormHelperText>
              Kontonummer som beløpet betales tilbake til.
            </FormHelperText>
            <FormErrorMessage>
              {errors.account_number && errors.account_number.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
        <HStack>
          <Button data-cy="send_button" isLoading={isPending} type="submit">
            Send
          </Button>
          <Button
            variant={"ghost"}
            onClick={handeSaveIncomplete}
            isLoading={saveIsPending}
          >
            Lagre fremdrift
          </Button>
        </HStack>
      </VStack>
    </Container>
  );
}

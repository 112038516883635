import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { authAxios } from "./axios";
import {
  AvalibleTeam,
  Invite,
  LogEntry,
  MonthTimeData,
  userDataResult,
} from "types";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import useSession from "hooks/useSession";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";

export function useSelectedTeamQuery() {
  return useQuery({
    queryKey: ["useSelectedTeamQuery"],
    queryFn: () => {
      return localStorage.getItem("selectedTeam");
    },
  });
}

export function useAvalibleTeamsForUserQuery() {
  return useQuery({
    queryKey: ["avalibleTeamsForUserQuery"],
    queryFn: () =>
      authAxios.get<AvalibleTeam[]>("/user/teams").then((res) => res.data),
  });
}

export function useTeamAdminData() {
  const { selectedTeam } = useSelectedTeamv2();
  return useQuery({
    queryKey: ["teamAdminData", selectedTeam],
    queryFn: () => {
      if (!selectedTeam) {
        return Promise.resolve(null);
      }
      return authAxios
        .get("/company/info", { params: { selectedTeam: selectedTeam } })
        .then((res) => res.data);
    },
    enabled: !!selectedTeam,
  });
}

export function useEmployeesInOrganizationQuery() {
  const { selectedTeam } = useSelectedTeamv2();
  return useQuery<userDataResult[]>({
    queryKey: ["employeesInOrganization", selectedTeam],
    queryFn: () => {
      if (!selectedTeam) {
        return Promise.resolve(null);
      }
      return authAxios
        .get("/company/employees", { params: { selectedTeam: selectedTeam } })
        .then((res) => res.data);
    },
    enabled: !!selectedTeam,
  });
}

export function useHoursForMonthQuery() {
  const { selectedTeam } = useSelectedTeamv2();
  const { month, year } = useSelectedMonth();
  return useQuery({
    queryKey: ["HoursForMonth", selectedTeam, month, year],
    queryFn: () => {
      if (!selectedTeam) {
        return Promise.resolve(null);
      }
      const params = {
        params: {
          selectedTeam: selectedTeam,
          month: month,
          year: year,
        },
      };
      return authAxios
        .get("timesheet/user/month", params)
        .then((res) => res.data);
    },
    enabled: !!selectedTeam,
  });
}

export function useHoursForMonthMobileInfiniteQuery() {
  const { selectedTeam } = useSelectedTeamv2();
  const { session } = useSession();
  return useInfiniteQuery({
    queryKey: ["HoursForMonthInifinite", selectedTeam, session?.user.id],
    queryFn: ({ pageParam }) => {
      const params = {
        params: {
          selectedTeam: selectedTeam,
          month: pageParam.month,
          year: pageParam.year,
          number_of_months: 3,
        },
      };

      return authAxios
        .get("timesheet/user/month/multiple", params)
        .then((res) => {
          return res.data as MonthTimeData[];
        });
    },
    initialPageParam: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    getNextPageParam: (lastPage) => lastPage[lastPage.length - 1].next_month,
    enabled: !!selectedTeam && !!session,
  });
}

export function useAllTimeEntriesInOrganizationQuery() {
  const { selectedTeam } = useSelectedTeamv2();
  const { month, year } = useSelectedMonth();
  return useQuery<MonthTimeData[]>({
    queryKey: ["allTimeEntriesInOrganization", selectedTeam, month, year],
    queryFn: () => {
      if (!selectedTeam) {
        return Promise.resolve(null);
      }
      const params = {
        params: {
          company_id: selectedTeam,
          month: month,
          year: year,
        },
      };

      return authAxios.get("/admin/timesheets", params).then((res) => res.data);
    },
    enabled: !!selectedTeam,
  });
}

export function useCompanyInvitesQuery() {
  const { selectedTeam } = useSelectedTeamv2();
  return useQuery<Invite[]>({
    queryKey: ["useCompanyInvites", selectedTeam],
    queryFn: () => {
      if (!selectedTeam) {
        return Promise.resolve(null);
      }
      return authAxios
        .get("/company/invites", { params: { selectedTeam: selectedTeam } })
        .then((res) => res.data);
    },
    enabled: !!selectedTeam,
  });
}

export function useMonthLogQuery(month_id: string | undefined) {
  return useQuery<LogEntry[]>({
    queryKey: ["monthLogQuery", month_id],
    queryFn: async () => {
      if (!month_id) {
        return Promise.resolve([]); // Return an empty array if month_id is falsy
      }
      const params = {
        params: {
          month_id: month_id,
        },
      };
      const response = await authAxios.get(`/timesheet/log`, params);
      return response.data; // Return the data property of the response
    },
  });
}

import { trpc } from "utils/trpc";
import { DateRangeEmployeesOverviewTable } from "../components/timesheet/DateRangeEmployeesOverviewTable";
import { useState } from "react";
import { FormControl, FormLabel, HStack, Select } from "@chakra-ui/react";
import { MonthSwitcher } from "components";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import { ModuleLayout } from "layout";
import { GenerateReport } from "../components/timesheet/GenerateReport";

export function RegisteredHoursOverview() {
  const { month, year } = useSelectedMonth();
  const [projectFilter, setProjectFilter] = useState<string>("");
  const { data: projects } = trpc.projectRouter.getProjects.useQuery();
  const { data: employees } =
    trpc.adminRouter.getEmployeesWithOverviewStatsForDateRange.useQuery({
      startDate: new Date(year, month, 1),
      endDate: new Date(year, month + 1, 0),
      projectId: projectFilter,
    });

  if (!employees) return null;
  return (
    <ModuleLayout pageTitle={"Oversikt timeføringer"}>
      <MonthSwitcher />
      <HStack my={5} justifyContent={"end"} alignItems={"end"}>
        <FormControl maxW={"xs"}>
          <FormLabel>Prosjekt</FormLabel>
          <Select
            value={projectFilter}
            onChange={(v) => setProjectFilter(v.target.value)}
          >
            <option value={""}>Alle</option>
            <option value={"no-project"}>Ingen prosjekt</option>
            {projects?.map((project) => (
              <option value={project.id}>{project.name}</option>
            ))}
          </Select>
        </FormControl>
        <GenerateReport employees={employees} />
      </HStack>
      <DateRangeEmployeesOverviewTable employees={employees} />
    </ModuleLayout>
  );
}

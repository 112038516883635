import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Center,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import FileSaver from "file-saver";
import { authAxios } from "lib/axios";
import { useSelectedTeamv2 } from "hooks/useSelectedTeamv2";
import { useSelectedMonth } from "hooks/useSelectedMonth";

export function GenerateSingleReport({
  user_id,
  user_name,
}: {
  user_id: string;
  user_name: string;
}) {
  const { month, year } = useSelectedMonth();
  const { selectedTeam } = useSelectedTeamv2();

  const toast = useToast();

  const generateReport = async (filetype: string) => {
    if (!selectedTeam) {
      return;
    }
    const exportOptions = {
      year: year,
      month: month,
      companyId: selectedTeam,
      userids: [user_id],
      filetype: filetype,
    };

    authAxios({
      method: "post",
      url: "/timesheet/export",
      responseType: "blob",
      data: exportOptions,
    })
      .then((res) => {
        const data = res.data;
        const filename = `${user_name}_${year}_${month + 1}.${filetype}`;
        FileSaver.saveAs(data, filename);
      })
      .catch((error) => {
        console.error(error);
        let message = "Undefined error";
        if (error.message) message = error.message;
        toast({
          title: "Kunne ikke opprette eksport!",
          status: "error",
          description: message,
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button leftIcon={<DownloadIcon />} aria-label={"Last ned rapport"}>
            Rapport
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Valg for rapport</PopoverHeader>
          <PopoverBody>
            <Center>
              <Flex gap={"10px"}>
                <Button
                  aria-label="csv eksport"
                  variant={"ghost"}
                  onClick={() => generateReport("csv")}
                  leftIcon={<FaFileCsv />}
                >
                  CSV
                </Button>
                <Button
                  aria-label="xlsx eksport"
                  variant={"ghost"}
                  onClick={() => generateReport("xlsx")}
                  leftIcon={<FaFileExcel />}
                >
                  XLSX
                </Button>
                <Button
                  aria-label="pdf eksport"
                  variant={"ghost"}
                  onClick={() => generateReport("pdf")}
                  leftIcon={<FaFilePdf />}
                >
                  PDF
                </Button>
              </Flex>
            </Center>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSelectedTeamQuery } from "lib/queries";
import {
  InviteEmployee,
  InviteEmployeeSchema,
} from "@timetable/server/src/validation/zod/adminOnboarding";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "utils/trpc";
import { showConfirmDialogue } from "components/AlertDialogue";
import { useNavigate } from "react-router-dom";
import { links } from "@timetable/server/src/utils/Links";

export function InviteEmployeeWithEmail() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const { data: organization_id } = useSelectedTeamQuery();
  const { data: team } = trpc.organizationRouter.getOrganization.useQuery({
    organization_id: organization_id ?? "",
  });
  const utils = trpc.useUtils();
  const { mutate, isPending } = trpc.adminRouter.inviteEmployee.useMutation({
    onError(error) {
      if (error.data?.code === "CONFLICT") {
        showConfirmDialogue({
          title: "For mange ansatte",
          description:
            "Du har brukt opp kvoten på antall ansatte for din plan. Vil du gå til siden for å oppgradere din plan?",
          variant: "warning",
          async onAccept() {
            navigate(links.companySettings + "?tabIdx=2");
          },
        });
      } else {
        toast({
          title: "Noe gikk galt",
          description: String(error),
          status: "error",
        });
      }
    },
    onSuccess() {
      utils.adminRouter.getInvitedEmployees.refetch();
      reset();
      onClose();
      toast({
        title: "Invitert!",
        status: "success",
      });
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteEmployee>({
    resolver: zodResolver(InviteEmployeeSchema),
  });

  function onSubmit(values: InviteEmployee) {
    mutate(values);
  }

  return (
    <>
      <Button variant={"ghost"} onClick={onOpen}>
        Ny ansatt
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inviter ansatt til {team?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              w={"100%"}
              direction={{ base: "column", md: "row" }}
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              id="invite_form"
            >
              <FormControl>
                <FormLabel>Epost</FormLabel>
                <Input
                  {...register("email")}
                  type="email"
                  placeholder="ola@normann.no"
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
                <FormHelperText>
                  Vi sender en invitasjon til denne epostadressen.
                </FormHelperText>
              </FormControl>
              <FormControl width={{ base: "100%", md: "210px" }}>
                <FormLabel>Rolle</FormLabel>
                <Select {...register("role")} placeholder="Velg rolle">
                  <option value="member">Medlem</option>
                  <option value="admin">Administrator</option>
                </Select>
                <FormErrorMessage>
                  {errors.role && errors.role.message}
                </FormErrorMessage>
                <FormHelperText>Rollen i bedriften.</FormHelperText>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                type="submit"
                form="invite_form"
                width={{ base: "100%", md: "100px" }}
                isLoading={isPending}
                px={6}
              >
                Inviter
              </Button>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Lukk
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

import { ViewIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { AvatarContainer, StandardTable } from "components";
import { formatter } from "lib";
import { formatHours } from "utils/formatHours";
import { RouterOutputs } from "utils/trpc";
import { Link as ReactRouterLink } from "react-router-dom";
import { getMonthStatusIcons } from "features/timesheet/components/shared";

type TableDataType =
  RouterOutputs["adminRouter"]["getEmployeesWithOverviewStatsForDateRange"];

export function DateRangeEmployeesOverviewTable(props: {
  employees: TableDataType;
}) {
  const columnHelper = createColumnHelper<TableDataType[number]>();

  const columns = [
    columnHelper.display({
      id: "avatar",
      cell: (props) => (
        <AvatarContainer
          src={props.row.original.avatar_url}
          fallbackName={`${props.row.original.first_name} ${props.row.original.last_name}`}
          size="md"
        />
      ),
    }),
    columnHelper.accessor("first_name", {
      header: "Navn",
      cell: (props) => `${props.getValue()} ${props.row.original.last_name}`,
    }),
    columnHelper.accessor("email", { header: "Epost" }),
    columnHelper.accessor("date_range_total_hours", {
      header: "Antall timer",
      cell: (props) =>
        `${formatHours(props.getValue() || 0)} (${props.getValue() || 0})`,
      footer: (data) => {
        const total = data.table
          .getFilteredRowModel()
          .rows.reduce(
            (sum, row) => sum + row.getValue<number>("date_range_total_hours"),
            0,
          );

        return `${formatHours(total)} (${total})`;
      },
    }),
    columnHelper.accessor("date_range_total_salary", {
      header: "Total lønn",
      cell: (props) => formatter.format(props.getValue() || 0),
      footer: (data) => {
        const total = data.table
          .getFilteredRowModel()
          .rows.reduce(
            (sum, row) => sum + row.getValue<number>("date_range_total_salary"),
            0,
          );

        return formatter.format(total);
      },
    }),
    columnHelper.accessor("locked", {
      header: "Status",
      cell: (props) =>
        getMonthStatusIcons(
          props.row.original.admin_approved_month,
          props.row.original.locked ?? false,
          true,
        ),
    }),
    columnHelper.display({
      header: "Handling",
      cell: (props) =>
        props.row.original.month_id !== null && (
          <IconButton
            as={ReactRouterLink}
            to={`/dashboard/admin/employee/${props.row.original.id}/month/${props.row.original.month_id}`}
            icon={<ViewIcon />}
            variant={"ghost"}
            aria-label="Se måned"
          />
        ),
    }),
  ];

  const table = useReactTable({
    columns,
    data: props.employees,
    getCoreRowModel: getCoreRowModel(),
  });

  return <StandardTable table={table} emptyTableMessage="Ingen ansatte" />;
}

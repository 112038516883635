import {
  VStack,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
} from "@chakra-ui/react";

import { RouterOutputs } from "utils/trpc";
import { CompanySettingsProfile } from "./CompanySettingsProfile";
import { CompanyBillingSettings } from "./CompanyBillingSettings";
import { CompanySettingsAttachments } from "./CompanySettingsAttachments";
import { useSearchParams } from "react-router-dom";
import { CompanySettingsProjects } from "./projects/CompanySettingsProjects";

export function CompanySettingsElement(props: {
  team: RouterOutputs["adminRouter"]["getTeamData"];
}) {
  const [params] = useSearchParams();

  const param = Number.parseInt(params.get("tabIdx") ?? "");
  let defaultIndex = 0;

  if (param) {
    if (param < 3 && param > -1) {
      defaultIndex = param;
    }
  }

  return (
    <VStack w={"100%"} pt={5} gap={"5"} alignItems={"flex-start"}>
      <Tabs
        w={"100%"}
        size={"lg"}
        variant="underline"
        defaultIndex={defaultIndex}
      >
        <CompanySettingsTabs />
        <TabPanels w={"100%"}>
          <CompanySettingsProfile team={props.team} />
          <CompanySettingsProjects />
          <CompanySettingsAttachments team={props.team} />
          <CompanyBillingSettings />
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

function CompanySettingsTabs() {
  const [, setParams] = useSearchParams();

  return (
    <>
      <TabList>
        <Tab onClick={() => setParams(new URLSearchParams({ tabIdx: "0" }))}>
          Bedriftsprofil
        </Tab>
        <Tab onClick={() => setParams(new URLSearchParams({ tabIdx: "1" }))}>
          Prosjekter
        </Tab>
        <Tab onClick={() => setParams(new URLSearchParams({ tabIdx: "2" }))}>
          Instillinger
        </Tab>
        <Tab onClick={() => setParams(new URLSearchParams({ tabIdx: "3" }))}>
          Abbonement
        </Tab>
      </TabList>
      <TabIndicator />
    </>
  );
}

import { DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { getParamHref, getSingleTimeString } from "utils";
import { rowStatusMap } from "./MyHoursTable";
import { formatter } from "lib";
import { formatTimeString, useDeleteTimesheetEntryMutation } from "./shared";
import { RouterOutputs } from "utils/trpc";
import { showConfirmDialogue } from "components/AlertDialogue";
import { Link as ReactRouterLink } from "react-router-dom";

function formatDateInNorwegian(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const repr = date.toLocaleDateString("no-NO", options);
  return repr[0].toUpperCase() + repr.slice(1, repr.length - 1);
}
export default function SingleEntryCard(props: {
  entry: RouterOutputs["timesheetRouter"]["getMobileMonth"]["data"][number]["entries"][number];
  month_status: boolean;
}) {
  const { entry } = props;
  return (
    <Card
      colorScheme="facebook"
      width={"100%"}
      variant={"elevated"}
      size={"sm"}
    >
      <CardHeader bg="#818CF8">
        <HStack justifyContent={"space-between"}>
          <HStack>
            <Text color={"white"}>
              {formatDateInNorwegian(new Date(props.entry.fromdate))}
            </Text>
            <Text color={"white"}>
              {getSingleTimeString(new Date(entry.fromdate)) +
                " - " +
                getSingleTimeString(new Date(entry.todate))}
            </Text>
          </HStack>
          <EntryCardMenu
            entryid={entry.entryid}
            editable={!props.month_status}
          />
        </HStack>
      </CardHeader>
      <CardBody bg="white">
        <HStack gap={5}>
          <Box>
            <Text as="b" size={"xs"}>
              Beskrivelse
            </Text>
            <Text>{entry.description}</Text>
          </Box>
          {entry.project_name && (
            <Box>
              <Text as="b" size={"xs"}>
                Prosjekt
              </Text>
              <Text>{entry.project_name}</Text>
            </Box>
          )}
        </HStack>
      </CardBody>
      <CardFooter>
        <HStack justifyContent={"space-between"} width={"100%"}>
          <Text>{formatTimeString(entry.totalhours ?? 0)}</Text>
          {entry.hourly_rate && entry.totalhours && (
            <Text>
              {formatter.format(entry.totalhours * entry.hourly_rate)}
            </Text>
          )}
          {props.month_status && rowStatusMap.get(entry.admin_approved)}
        </HStack>
      </CardFooter>
    </Card>
  );
}

function EntryCardMenu(props: { entryid: string; editable: boolean }) {
  const navigate = useNavigate();
  const { mutateAsync } = useDeleteTimesheetEntryMutation();

  function handleDeleteClick() {
    showConfirmDialogue({
      title: "Slett timeføring",
      description: "Er du sikker på at du vil slette timeføringen?",
      async onAccept() {
        await mutateAsync(props.entryid);
      },
      variant: "delete",
    });
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        color={"white"}
        aria-label="Entry options"
        icon={<HiDotsVertical />}
      />
      <MenuList>
        <MenuItem
          icon={<ViewIcon />}
          as={ReactRouterLink}
          to={`/dashboard/admin/timesheet/${props.entryid}`}
          aria-label="vis timeføring"
        >
          Vis timføring
        </MenuItem>
        {props.editable && (
          <>
            {" "}
            <MenuItem
              icon={<EditIcon />}
              onClick={() =>
                navigate(getParamHref("timesheet_edit", props.entryid))
              }
            >
              Rediger
            </MenuItem>
            <MenuItem icon={<DeleteIcon />} onClick={handleDeleteClick}>
              Slett
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
}

import { Text, Button, Stack } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { links } from "utils";

export function NoEntryPage() {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Text>Kan ikke vise dette utlegget</Text>
      <Button as={ReactRouterLink} to={links.attachments}>
        Gå tilbake
      </Button>
    </Stack>
  );
}

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { IconButton, Link } from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { StandardTable } from "components";
import { RouterOutputs } from "utils/trpc";
import { Link as ReactRouterLink } from "react-router-dom";
import { getParamHref } from "@timetable/server/src/utils/Links";

type TableRows = RouterOutputs["projectRouter"]["getProjects"];

export function CompanySettingsProjectsTable(props: {
  projects: TableRows;
  handleDeleteProject: (project: TableRows[number]) => void;
}) {
  const columnHelper = createColumnHelper<TableRows[number]>();

  const columns = [
    columnHelper.accessor("name", { header: "Prosjektnavn" }),
    columnHelper.accessor("created_at", {
      header: "Opprettet",
      cell: (props) => props.getValue()?.toLocaleDateString("nb-NO"),
    }),
    columnHelper.display({
      header: "Handling",
      cell: (cellProps) => (
        <>
          <Link
            as={ReactRouterLink}
            to={getParamHref("edit_project", cellProps.row.original.id)}
          >
            <IconButton
              icon={<EditIcon />}
              variant={"ghost"}
              aria-label="Rediger projekt"
            />
          </Link>
          <IconButton
            icon={<DeleteIcon />}
            variant={"ghost"}
            aria-label="Slett prosjekt"
            onClick={() => props.handleDeleteProject(cellProps.row.original)}
          />
        </>
      ),
    }),
  ];

  const table = useReactTable({
    columns: columns,
    data: props.projects,
    getCoreRowModel: getCoreRowModel(),
  });

  return <StandardTable table={table} emptyTableMessage="Ingen prosjekter" />;
}

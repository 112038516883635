import { DownloadIcon } from "@chakra-ui/icons";
import { HStack, VStack, Text, IconButton } from "@chakra-ui/react";
import { AvatarContainer } from "components";
import { downloadCopyofUserDoc } from "../../../../utils";
import { useState } from "react";

type DetailBannerProps = {
  organizationName: string;
  organizationAvatarUrl?: string;
  attachmentId: string;
  attachmentTitle: string;
};

export function DetailBanner(props: DetailBannerProps) {
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);
  return (
    <HStack w={"100%"} justifyContent={"space-between"}>
      <HStack>
        <AvatarContainer
          src={props.organizationAvatarUrl}
          fallbackName={props.organizationName}
          size="md"
        />
        <VStack alignItems={"start"}>
          <Text>Utlegg #{props.attachmentId}</Text>
        </VStack>
      </HStack>
      <HStack>
        <IconButton
          onClick={() => {
            downloadCopyofUserDoc(
              Number.parseInt(props.attachmentId),
              props.attachmentTitle,
              (v) => {
                if (v === undefined) {
                  setIsFetchingDocument(false);
                } else {
                  setIsFetchingDocument(true);
                }
              },
            );
          }}
          isLoading={isFetchingDocument}
          aria-label="Last ned utlegg"
          variant={"ghost"}
          icon={<DownloadIcon />}
        />
      </HStack>
    </HStack>
  );
}

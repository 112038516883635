import { mod11SecurityDigitValid } from "../../utils/utils.js";
import { z } from "zod";

export const UpdateProfileSchema = z.object({
  street_address: z.string(),
  postal_code: z.string(),
  postal_place: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  account_number: z
    .string()
    .refine((v) => mod11SecurityDigitValid(v, "ACCOUNT_NUMBER"), {
      message: "Kontonummer er ikke et gyldig kontonummer",
    }),
  send_attachment: z.boolean(),
});
export type UpdateProfile = z.infer<typeof UpdateProfileSchema>;

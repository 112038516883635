import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ThemeProvider,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { theme } from "theme";

type ShowConfirmDialogueProps = {
  title: string;
  description: string;
  variant: "delete" | "confirm" | "warning";
  onAccept: () => Promise<void>;
  onDecline?: () => void;
};

function AlertRoot(props: { opts: ShowConfirmDialogueProps }) {
  const { opts } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  const confirm = async () => {
    setIsLoading(true);
    await opts.onAccept();
    setIsLoading(false);
    onClose();
  };

  const cancel = () => {
    opts.onDecline && opts.onDecline();
    onClose();
  };

  const colors: Record<ShowConfirmDialogueProps["variant"], string> = {
    confirm: "brand",
    delete: "red",
    warning: "orange",
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AlertDialog
          motionPreset="scale"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {opts.title}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{opts.description}</AlertDialogBody>
            <AlertDialogFooter>
              <Button variant="ghost" ref={cancelRef} onClick={cancel}>
                Avbryt
              </Button>
              <Button
                ml={3}
                onClick={confirm}
                isLoading={isLoading}
                colorScheme={colors[opts.variant]}
              >
                Fortsett
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </ThemeProvider>
    </>
  );
}

export function showConfirmDialogue(opts: ShowConfirmDialogueProps) {
  const rootID = "temp";
  let div = document.getElementById(rootID);

  if (!div) {
    div = document.createElement("div");
    div.id = rootID;
    document.body.appendChild(div);
  }

  const root = createRoot(div);
  root.render(<AlertRoot opts={opts} />);

  if (div) {
    div.remove();
  }
}

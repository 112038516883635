import {
  FormControl,
  FormLabel,
  VStack,
  Input,
  FormHelperText,
  FormErrorMessage,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { AttachmentsUploader } from "./AttachmentsUploader";
import { SendAttachmentType } from "@timetable/server/src/validation/zod/bilag";

interface BaseFormProps {
  register: UseFormRegister<SendAttachmentType>;
  errors: FieldErrors<SendAttachmentType>;
  attachmentId: string;
}

export const BaseForm: React.FC<BaseFormProps> = ({
  register,
  errors,
  attachmentId,
}) => {
  return (
    <>
      <VStack w={"full"} alignItems={"flex-start"}>
        <Heading size={"sm"}>Kjøpsinformasjon</Heading>
        <FormControl isInvalid={errors.item ? true : false}>
          <FormLabel>Varekjøp</FormLabel>
          <Input
            type="text"
            data-cy="item_field"
            {...register("item", {
              required: "Du må spesifisere en vare",
            })}
          />
          <FormHelperText>Varen(e) du kjøpte</FormHelperText>
          <FormErrorMessage>
            {errors.item && errors.item.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.item ? true : false}>
          <FormLabel>Beskrivelse</FormLabel>
          <Textarea
            data-cy="description_field"
            {...register("description", {
              required: "Du må spesifisere en beskrivelse",
            })}
          />
          <FormHelperText>Begrunnelse for utlegget</FormHelperText>
          <FormErrorMessage>
            {errors.description && errors.description.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.amount ? true : false}>
          <FormLabel>Beløp</FormLabel>
          <Input
            data-cy="amount"
            type="number"
            step={"0.01"}
            {...register("amount", {
              required: "Du må angi et beløp",
              valueAsNumber: true,
            })}
          />
          <FormHelperText>Totalsum for kjøpet</FormHelperText>
          <FormErrorMessage>
            {errors.amount && errors.amount.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.date_of_purchase ? true : false}>
          <FormLabel>Dato</FormLabel>
          <Input
            data-cy="date_of_purchase"
            type="date"
            {...register("date_of_purchase", {
              required: "Du må angi dato for kjøp.",
            })}
          />
          <FormHelperText>Datoen du kjøpte varen(e)</FormHelperText>
          <FormErrorMessage>
            {errors.date_of_purchase && errors.date_of_purchase.message}
          </FormErrorMessage>
        </FormControl>

        <AttachmentsUploader attachmentId={attachmentId} />
      </VStack>
    </>
  );
};

import { Button, HStack, Link, TabPanel, useToast } from "@chakra-ui/react";
import { RouterOutputs, trpc } from "utils/trpc";
import { CompanySettingsProjectsTable } from "./CompanySettingsProjectsTable";
import { Link as ReactRouterLink } from "react-router-dom";
import { links } from "@timetable/server/src/utils/Links";
import { showConfirmDialogue } from "components/AlertDialogue";

export function CompanySettingsProjects() {
  const toast = useToast();
  const utils = trpc.useUtils();
  const { data: projects } = trpc.projectRouter.getProjects.useQuery();
  const { mutateAsync } = trpc.projectRouter.deleteProject.useMutation({
    onError(error) {
      toast({
        title: "Kunne ikke slette prosjekt.",
        description: String(error),
        status: "error",
      });
    },
    async onSuccess(_, variables) {
      await utils.projectRouter.getProjects.invalidate();
      await utils.projectRouter.getProject.invalidate({
        project_id: variables.project_id,
      });
      toast({
        title: "Prosjekt slettet",
        status: "success",
      });
    },
  });

  function handleDeleteProject(
    project: RouterOutputs["projectRouter"]["getProjects"][number],
  ) {
    showConfirmDialogue({
      title: `Er du sikker på at du vil slette ${project.name}`,
      description:
        "Dette fjerner ikke timeføringer eller utlegg, men fjerner assosiasjonen til prosjekt.",
      variant: "delete",
      async onAccept() {
        await mutateAsync({ project_id: project.id });
      },
    });
  }

  if (!projects) return null;
  return (
    <TabPanel>
      <HStack justifyContent={"end"} my={4}>
        <Link as={ReactRouterLink} to={links.new_project}>
          <Button>Nytt prosjekt</Button>
        </Link>
      </HStack>
      <CompanySettingsProjectsTable
        handleDeleteProject={handleDeleteProject}
        projects={projects}
      />
    </TabPanel>
  );
}

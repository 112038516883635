import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { formatter } from "lib";
import { getDateString, getStatusElements } from "utils";
import { downloadCopyofUserDoc } from "utils";
import { Avatar, HStack, Box, Text } from "@chakra-ui/react";
import { MidLoadingSpinner, StandardTable, PagiationButtons } from "components";
import { SentActionButton } from "features/utlegg/components/SentActionButtons";
import { RouterOutputs, trpc } from "utils/trpc";

export const InternalAttachments = () => {
  const {
    data: attachmentData,
    isLoading,
    error,
  } = trpc.bilagRouter.getInteralBilag.useQuery();
  if (isLoading) {
    return <MidLoadingSpinner />;
  }

  if (!error && attachmentData) {
    return <InternalAttachmentsTable attachmentsData={attachmentData} />;
  }
};

interface InternalAttachmentsTableProps {
  attachmentsData: RouterOutputs["bilagRouter"]["getInteralBilag"];
}

const InternalAttachmentsTable: React.FC<InternalAttachmentsTableProps> = ({
  attachmentsData,
}) => {
  const columnHelper =
    createColumnHelper<
      RouterOutputs["bilagRouter"]["getInteralBilag"][number]
    >();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isFetching, setIsFetchingDocument] = useState<number | undefined>();

  const defaultColumns = [
    columnHelper.accessor("user.first_name", {
      id: "fornavn",
      header: "Ansatt",
      cell: (props) => {
        return (
          <HStack spacing="3">
            <Avatar
              name={props.row.original.user?.first_name ?? ""}
              src={props.row.original.user?.avatar_url}
              boxSize="10"
            />
            <Box>
              <Text fontWeight="medium">
                {props.row.original.user?.first_name}
              </Text>
            </Box>
          </HStack>
        );
      },
    }),
    columnHelper.accessor("title", {
      header: "Navn",
    }),
    columnHelper.accessor("amount", {
      header: "Beløp",
      cell: (prop) => formatter.format(prop.getValue() ?? 0),
    }),
    columnHelper.accessor("created_at", {
      header: "Dato opprettet",
      cell: (prop) => {
        const value = prop.getValue();
        const date = value ? new Date(value) : new Date();
        return getDateString(date);
      },
    }),
    columnHelper.accessor("date_of_purchase", {
      header: "Dato for kjøp",
      cell: (prop) =>
        prop.getValue() ? getDateString(new Date(prop.getValue() ?? "")) : "",
    }),
    columnHelper.accessor("project_name", {
      header: "Prosjekt",
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (prop) => getStatusElements(prop.row.original.current_status),
    }),
    columnHelper.display({
      header: "Handling",
      cell: (prop) => (
        <SentActionButton
          fetchingDocument={isFetching}
          id={Number.parseInt(prop.row.original.id)}
          download={() =>
            downloadCopyofUserDoc(
              Number.parseInt(prop.row.original.id),
              prop.row.original.title,
              setIsFetchingDocument,
            )
          }
        />
      ),
    }),
  ];
  const table = useReactTable({
    data: attachmentsData,
    columns: defaultColumns,
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <StandardTable table={table} emptyTableMessage={"Ingen utlegg funnet."} />
      <PagiationButtons table={table} />
    </>
  );
};

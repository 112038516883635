import { useParams } from "react-router-dom";
import { months } from "utils";
import { trpc } from "utils/trpc";
import { HoursTable } from "../components/timesheet/HoursTable";
import { ModuleLayout } from "layout";

export function MonthDetailPage() {
  const { user_id, month_id } = useParams();
  if (!user_id) {
    throw new Error("This page needs user_id to be defined.");
  }

  if (!month_id) {
    throw new Error("This page needs month_id to be defined.");
  }
  const { data: user_month } = trpc.adminRouter.getUserMonthOverview.useQuery({
    user_id: user_id,
    month_id: month_id,
  });

  if (!user_month) return null;

  return (
    <ModuleLayout
      pageTitle={`${months[user_month.month]} ${user_month.year} for ${user_month.first_name} ${user_month.last_name}`}
    >
      <HoursTable timeData={user_month} />
    </ModuleLayout>
  );
}

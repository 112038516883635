import { MyHoursTable } from "../components/MyHoursTable";
import { MonthSwitcher } from "components";
import { ModuleLayout } from "layout";
import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { NewHourEntry } from "../components/NewHourEntry";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import { MyHoursMobile } from "./MyHoursMobile";
import { adminApprovalStatus, monthStatus } from "../components/shared";
import { trpc } from "utils/trpc";
import { SendForApprovalButton } from "../components/SendForApprovalButton";

export const MyHours = () => {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  if (isMobile) {
    return <MyHoursMobile />;
  } else {
    return <MyHoursDesktop />;
  }
};

const MyHoursDesktop = () => {
  const { month, year } = useSelectedMonth();
  const { data } = trpc.timesheetRouter.getDesktopMonth.useQuery({
    month: month,
    year: year,
  });

  if (!data) return null;

  return (
    <ModuleLayout pageTitle={"Mine timeføringer"} minWidth="container.lg">
      <MonthSwitcher />
      <ActionButtons
        month_id={data.id}
        status={data.locked}
        admin_approval={data.admin_approved_month ?? 0}
      />
      <MyHoursTable entries={data.entries} status={data.locked} />
    </ModuleLayout>
  );
};

const ActionButtons = (props: {
  status: boolean;
  admin_approval: number;
  month_id: string;
}) => {
  return (
    <Box px={{ base: "4", md: "6" }} py="5">
      <Stack direction={"row"} justify="end">
        {props.admin_approval === 0 && !props.status ? null : (
          <HStack alignItems={"start"}>
            <VStack gap={0}>
              <Text fontSize={12} py={0} my={0}>
                Måned
              </Text>
              {props.status
                ? monthStatus.get("closed")
                : monthStatus.get("open")}
            </VStack>
            <VStack gap={0}>
              <Text fontSize={12} py={0} my={0}>
                Godkjenning
              </Text>
              {adminApprovalStatus.get(props.admin_approval)}
            </VStack>
          </HStack>
        )}
        {!props.status && (
          <HStack>
            <SendForApprovalButton month_id={props.month_id}>
              <Button size={"sm"}>Send til godkjenning</Button>
            </SendForApprovalButton>
            <NewHourEntry />
          </HStack>
        )}
      </Stack>
    </Box>
  );
};

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Select,
  VStack,
} from "@chakra-ui/react";
import { StandardTable } from "components";
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateString, getDoubleTimeString } from "utils";
import { useState } from "react";
import { formatter } from "../../../../lib";
import { Link as ReactRouterLink } from "react-router-dom";
import { formatHours } from "utils/formatHours";
import { RouterOutputs } from "utils/trpc";
import { ViewIcon } from "@chakra-ui/icons";
import { GenerateSingleReport } from "./GenerateSingleReport";
import { ReopenMonthButton } from "./ReopenMonthButton";

type TimeDataType = NonNullable<
  RouterOutputs["adminRouter"]["getUserMonthOverview"]
>;

export function HoursTable({ timeData }: { timeData: TimeDataType }) {
  const columnHelper = createColumnHelper<TimeDataType["entries"][number]>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const projects = timeData.entries
    .filter((entry) => entry.project_name && entry.project_id)
    .map((entry) => {
      return {
        project_id: entry.project_id ?? "",
        project_name: entry.project_name ?? "",
      };
    });

  const defaultColumns = [
    columnHelper.accessor("fromdate", {
      header: "Dato",
      cell: (props) => getDateString(new Date(props.row.original.fromdate)),
    }),
    columnHelper.accessor("todate", {
      header: "Tidsrom",
      cell: (props) =>
        getDoubleTimeString(
          new Date(props.row.original.fromdate),
          new Date(props.row.original.todate),
        ),
    }),
    columnHelper.accessor("totalhours", {
      header: "Antall timer",
      cell: (props) =>
        `${formatHours(props.getValue() ?? 0)} (${props.getValue()})`,
      footer: (data) => {
        const total = data.table
          .getFilteredRowModel()
          .rows.reduce(
            (sum, row) => sum + row.getValue<number>("totalhours"),
            0,
          );
        return `${formatHours(total)} (${total})`;
      },
    }),
    columnHelper.accessor("project_name", {
      id: "project_name",
      header: "Prosjekt",
      filterFn: (row, _, filterValue) => {
        if (filterValue === "no-project") {
          return row.original.project_name == null;
        }

        if (filterValue === "") {
          return true;
        }

        return filterValue === row.original.project_name;
      },
    }),
    columnHelper.accessor("entry_pay", {
      id: "entry_pay",
      header: "Brutto",
      cell: (props) => formatter.format(props.getValue() ?? 0),
      footer: (data) => {
        const total = data.table
          .getFilteredRowModel()
          .rows.reduce((sum, row) => {
            return sum + row.getValue<number>("entry_pay");
          }, 0);

        return formatter.format(total);
      },
    }),
    columnHelper.accessor("entry_hourly_rate", {
      header: "Timelønn",
      cell: (props) => formatter.format(props.getValue() ?? 0),
    }),
    columnHelper.accessor("description", {
      header: "Beskrivelse",
      cell: (props) =>
        props.getValue().length > 20
          ? `${props.getValue().slice(0, 20)}...`
          : props.getValue(),
    }),
    columnHelper.display({
      header: "Handling",
      cell: (props) => (
        <IconButton
          aria-label="Se timeføring"
          variant={"ghost"}
          icon={<ViewIcon />}
          as={ReactRouterLink}
          to={`/dashboard/admin/timesheet/${props.row.original.entryid}`}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: timeData.entries,
    columns: defaultColumns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const project_column = table.getColumn("project_name");

  return (
    <VStack gap={5} mt={5}>
      <HStack w={"full"} alignItems={"end"} justifyContent={"end"}>
        <FormControl maxW={"xs"}>
          <FormLabel>Prosjekt</FormLabel>
          <Select
            value={project_column?.getFilterValue()?.toString()}
            onChange={(e) => project_column?.setFilterValue(e.target.value)}
          >
            <option value={""}>Alle</option>
            <option value={"no-project"}>Ingen prosjekt</option>
            {projects?.map((project) => (
              <option value={project.project_name}>
                {project.project_name}
              </option>
            ))}
          </Select>
        </FormControl>

        {timeData.locked &&
          (timeData.admin_approved_month === 0 ||
            timeData.admin_approved_month === 1) && (
            <Button
              as={ReactRouterLink}
              to={`/dashboard/admin/hours/approve/${timeData.user_id}?month=${timeData.month}&year=${timeData.year}`}
            >
              Godkjenning
            </Button>
          )}
        {timeData.locked && (
          <ReopenMonthButton
            month_id={timeData.id}
            user_id={timeData.user_id}
            month_index={timeData.month}
            year={timeData.year}
          />
        )}
        <GenerateSingleReport
          user_id={timeData.user_id}
          user_name={`${timeData.first_name} ${timeData.last_name}`}
        />
      </HStack>
      <StandardTable
        table={table}
        emptyTableMessage={"Ingen timeføringer funnet."}
      />
    </VStack>
  );
}

import {
  Text,
  Box,
  Button,
  HStack,
  Icon,
  Square,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { FileObject } from "@supabase/storage-js";
import { FiFileText } from "react-icons/fi";
import { trpc } from "../../../utils/trpc";
import FileSaver from "file-saver";
import { TRPCError } from "@trpc/server";

export function UploadedFile({
  file,
  attachment_id,
}: {
  file: FileObject;
  attachment_id: string;
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const {
    mutate: downloadAttachmentMutate,
    isPending: downloadAttachmentIsPending,
  } = trpc.bilagRouter.downloadAttachment.useMutation({
    onSuccess(data, variables) {
      FileSaver.saveAs(data.signedUrl, variables.path.split("/")[1]);
    },
    onError(error, variables) {
      console.error(error);
      if (error instanceof TRPCError) {
        toast({
          title: "Kunne ikke laste ned vedlegg",
          description: error.message,
          status: "error",
        });
      } else {
        toast({
          title: "En ukjent feil har oppstått",
          description: String(error),
          status: "error",
        });
      }
    },
  });

  const {
    mutate: deleteAttachmentMutate,
    isPending: deleteAttachmentIsPending,
  } = trpc.bilagRouter.deleteAttachment.useMutation({
    async onSuccess(_, variables) {
      await utils.bilagRouter.getFilesInBucket.invalidate({
        attachment_id: variables.attachment_id,
      });
      toast({
        title: "Vedlegg fjernet!",
        status: "success",
      });
    },
    onError(error) {
      console.error(error);
      if (error instanceof TRPCError) {
        toast({
          title: "Kunne ikke slette vedlegg",
          description: error.message,
          status: "error",
        });
      } else {
        toast({
          title: "En ukjent feil har oppstått",
          description: String(error),
          status: "error",
        });
      }
    },
  });

  return (
    <Box
      borderWidth={{ base: "0", md: "1px" }}
      p={{ base: "0", md: "4" }}
      borderRadius="lg"
      width={"full"}
      key={file.name}
    >
      <Stack
        justify="space-between"
        direction={{ base: "column", md: "row" }}
        spacing="5"
      >
        <HStack spacing="3">
          <Square size="10" bg="bg.subtle" borderRadius="lg">
            <Icon as={FiFileText} boxSize="5" />
          </Square>
          <Box fontSize="sm">
            <Text color="empahsized" fontWeight="medium">
              {file.name}
            </Text>
            <Text color="fg.muted">
              {humanFileSize(file.metadata.size, true, 1)}
            </Text>
          </Box>
        </HStack>
        <Stack spacing="3" direction={{ base: "column-reverse", md: "row" }}>
          <Button
            variant="secondary"
            isLoading={deleteAttachmentIsPending}
            onClick={() =>
              deleteAttachmentMutate({
                attachment_id: attachment_id,
                path: `${attachment_id}/${file.name}`,
              })
            }
          >
            Slett
          </Button>
          <Button
            isLoading={downloadAttachmentIsPending}
            onClick={() =>
              downloadAttachmentMutate({
                attachment_id: attachment_id,
                path: `${attachment_id}/${file.name}`,
              })
            }
          >
            Last ned
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
// @ts-ignore
import nb_NO from "@uppy/locales/lib/nb_NO";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { supabase } from "lib";
import { FormControl, FormLabel, VStack } from "@chakra-ui/react";
import Compressor from "@uppy/compressor";
import { UploadedFile } from "./UploadedFile";
import { trpc } from "../../../utils/trpc";
interface AttachmentsUploaderProps {
  attachmentId: string;
}

export const AttachmentsUploader: React.FC<AttachmentsUploaderProps> = ({
  attachmentId,
}) => {
  const utils = trpc.useUtils();
  const { data: files } = trpc.bilagRouter.getFilesInBucket.useQuery({
    attachment_id: attachmentId,
  });
  const [uppy] = useState(() =>
    createUserAttachmentUppy(String(attachmentId) ?? ""),
  );

  useEffect(() => {
    async function addTus() {
      uppy.use(Tus, {
        endpoint: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/upload/resumable`,
        headers: {
          authorization: `Bearer ${
            (await supabase.auth.getSession()).data.session?.access_token
          }`,
        },
        id: `attachmentUploaderTus_${attachmentId}`,
        chunkSize: 6 * 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      });
      uppy.on("file-added", (file) => {
        file.meta = {
          ...file.meta,
          bucketName: "attachments",
          objectName: `${attachmentId}/${getSafeFileName(file.name)}`,
          contentType: file.type,
        };
      });

      uppy.on("complete", () => {
        void utils.bilagRouter.getFilesInBucket.invalidate({
          attachment_id: attachmentId,
        });
      });
    }
    addTus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!files) return null;
  return (
    <>
      {files.length > 0 && (
        <FormControl>
          <FormLabel>Opplastede bilag</FormLabel>
          <VStack wrap={"wrap"} alignItems={"flex-start"}>
            {files.map((file) => (
              <UploadedFile
                key={file.id}
                file={file}
                attachment_id={attachmentId}
              />
            ))}
          </VStack>
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Legg til bilag</FormLabel>
        <Dashboard uppy={uppy} />
      </FormControl>
    </>
  );
};

function createUserAttachmentUppy(attachment_id: string) {
  return new Uppy({
    locale: nb_NO,
    restrictions: {
      allowedFileTypes: [".jpeg", ".jpg", ".png", ".pdf"],
    },
    id: `attachmentUploader_${attachment_id}`,
  }).use(Compressor);
}

function getSafeFileName(name: string) {
  let finalName = "";
  for (let i = 0; i < name.length; i++) {
    if (
      "0123456789abcdefghijklmnopqrstwvxyzABCDEFGHIKLMNOPQRSTWVXYZ!-_.*'()".includes(
        name[i],
      )
    ) {
      finalName += name[i];
    } else {
      if ("øØ".includes(name[i])) {
        finalName += "o";
      }
      if ("æÆ".includes(name[i])) {
        finalName += "e";
      }
      if ("åÅ".includes(name[i])) {
        finalName += "aa";
      }
    }
  }
  return finalName;
}

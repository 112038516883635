import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import { HStack, IconButton } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { getParamHref } from "utils";

export function SentActionButton(props: {
  fetchingDocument: number | undefined;
  id: number;
  download: () => void;
}) {
  return (
    <HStack>
      <IconButton
        icon={<ViewIcon />}
        aria-label="Vis utlegg"
        as={ReactRouterLink}
        to={getParamHref("detail_attachment", props.id)}
      />

      <IconButton
        data-cy="download_button"
        variant={"ghost"}
        icon={<DownloadIcon />}
        isLoading={props.fetchingDocument === props.id}
        onClick={props.download}
        aria-label={"Last ned utlegg"}
      />
    </HStack>
  );
}

import { useToast } from "@chakra-ui/react";
import { MidLoadingSpinner } from "components";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { RouterInputs, RouterOutputs, trpc } from "utils/trpc";
import { ProjectFormElements } from "./ProjectForm";
import * as Sentry from "@sentry/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateProjectSchema } from "@timetable/server/src/validation/zod/projects";

export function EditProject() {
  const { project_id } = useParams();

  if (!project_id) {
    throw new Error("No project id provided!");
  }

  const { data: project, isLoading } = trpc.projectRouter.getProject.useQuery({
    project_id: project_id,
  });
  const { data: users_in_organization, isLoading: isLoadingEmployees } =
    trpc.adminRouter.getEmployees.useQuery();

  if (isLoading || isLoadingEmployees) {
    return <MidLoadingSpinner />;
  }

  if (!project || !users_in_organization) {
    return <p>Fant ikke prosjekt</p>;
  }

  return (
    <EditProjectForm
      project={project}
      users_in_organization={users_in_organization}
    />
  );
}

type FormType = Omit<
  RouterInputs["projectRouter"]["updateProject"],
  "project_id"
>;

function EditProjectForm(props: {
  project: NonNullable<RouterOutputs["projectRouter"]["getProject"]>;
  users_in_organization: NonNullable<
    RouterOutputs["adminRouter"]["getEmployees"]
  >;
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { mutate } = trpc.projectRouter.updateProject.useMutation({
    onSuccess(_, variables) {
      toast({
        title: "Prosjekt oppdatert",
        status: "success",
      });
      void utils.projectRouter.getProject.refetch({
        project_id: variables.project_id,
      });
      navigate(-1);
    },
    onError(error) {
      Sentry.captureException(error);
      toast({
        title: "Kunne ikke oppdatere prosjekt.",
      });
    },
  });

  const form = useForm<FormType>({
    defaultValues: {
      name: props.project.name,
      users: props.project.users_in_project.map((v) => v.id),
    },
    resolver: zodResolver(CreateProjectSchema),
  });

  function onSubmit(data: FormType) {
    mutate({ ...data, project_id: props.project.id });
  }

  return (
    <ProjectFormElements
      users_in_organization={props.users_in_organization}
      form={form}
      onSubmit={onSubmit}
      formType="UPDATE"
    />
  );
}

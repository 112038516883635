import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import React, { useState } from "react";
import { trpc } from "./utils/trpc";
import superjson from "superjson";
import { supabase } from "lib";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient();

export function TrpcProvider(props: { children: React.ReactNode }) {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: import.meta.env.VITE_BACKEND_URL + "/trpc",
          async headers() {
            const session = await supabase.auth.getSession();
            const organization_id = await localStorage.getItem("selectedTeam");
            return {
              authorization: `Bearer ${session?.data?.session?.access_token}`,
              organization_id: organization_id ?? "",
            };
          },
          transformer: superjson,
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={true} />
        {props.children}
      </QueryClientProvider>
    </trpc.Provider>
  );
}

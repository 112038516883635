import {
  Text,
  Divider,
  VStack,
  Stack,
  Box,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { trpc } from "utils/trpc";
import { DetailBanner } from "../components/detail/DetailBanner";
import { formatter } from "lib";
import { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { DetailSection } from "../components/detail/DetailSection";
import { getDateString } from "utils/dateStrings";
import { MidLoadingSpinner } from "components";
import { NoEntryPage } from "../components/detail/NoEntryPage";

export function UtleggDetail() {
  const { attachmentId } = useParams();
  if (!attachmentId) throw new Error("No attachment ID!");
  const { data, error, isLoading } = trpc.bilagRouter.getBilagDetail.useQuery({
    attachmentId: attachmentId,
  });
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);

  if (isLoading) return <MidLoadingSpinner />;
  if (error) return <NoEntryPage />;

  if (!data) return null;
  const sender_details: { title: string; value: string }[] = [
    {
      title: "Navn",
      value:
        (data.attachment.user?.first_name ?? "") +
        " " +
        (data.attachment.user?.last_name ?? ""),
    },
    {
      title: "Epost",
      value: data.attachment.user?.email ?? "",
    },
    {
      title: "Gateadresse",
      value: data.attachment.user?.street_address ?? "",
    },
    {
      title: "Poststed",
      value:
        data.attachment.user?.postal_code ??
        "" + data.attachment.user?.postal_place ??
        "",
    },
    { title: "Kontonummer", value: data.attachment.user?.accountnumber ?? "" },
  ];

  const utlegg_details: ({ title: string; value: string } | null)[] = [
    { title: "Vare", value: data.attachment.item ?? "" },
    { title: "Beskrivelse", value: data.attachment.description ?? "" },
    { title: "Beløp", value: formatter.format(data.attachment.amount ?? 0) },
    {
      title: "Kjøpsdato",
      value: getDateString(data.attachment.date_of_purchase ?? new Date()),
    },
    data.attachment.project_name
      ? {
          title: "Prosjekt",
          value: data.attachment.project_name ?? "",
        }
      : null,
    { title: "Antall vedlegg", value: data.urls.length.toString() ?? "0" },
  ];
  const hasAttachments = data.urls.length > 0;
  const image_mimes = ["jpeg", "jpg", "png"];
  const isCurrentAttachmentImage =
    data.urls.length > 0 &&
    image_mimes.includes(
      data.urls[selectedAttachmentIndex].path?.split(".").at(-1) ?? "",
    );

  return (
    <VStack w={"full"} p={{ base: 3, md: 10 }}>
      <DetailBanner
        organizationName={
          (data.attachment.organization && data.attachment.organization.name) ??
          ""
        }
        organizationAvatarUrl={
          (data.attachment.organization &&
            data.attachment.organization.avatar_url) ??
          ""
        }
        attachmentId={data.attachment.id}
        attachmentTitle={data.attachment.title}
      />
      <Divider />
      <Stack
        w={"full"}
        flexDirection={{
          base: "column",
          xl: hasAttachments ? "row" : "column",
        }}
        justifyContent={{ lg: "space-between" }}
      >
        <Stack
          flexDirection={{
            base: "column",
            md: hasAttachments ? "column" : "row",
          }}
          justifyContent={"space-between"}
          width={"full"}
        >
          <DetailSection
            heading="Avsenderinformasjon"
            details={sender_details}
          />

          <DetailSection heading="Kjøpsinformasjon" details={utlegg_details} />
        </Stack>
        {data.urls.length > 0 && (
          <Box minW={{ base: "full", md: "3xl" }}>
            <HStack justifyContent={"center"} mb={3}>
              <IconButton
                variant={"ghost"}
                icon={<ArrowLeftIcon />}
                isDisabled={selectedAttachmentIndex === 0}
                aria-label="Forrige vedlegg"
                onClick={() => {
                  if (selectedAttachmentIndex === 0) return;
                  setSelectedAttachmentIndex(selectedAttachmentIndex - 1);
                }}
              />
              <Text>
                Vedlegg {selectedAttachmentIndex + 1} av {data.urls.length}
              </Text>
              <IconButton
                variant={"ghost"}
                isDisabled={selectedAttachmentIndex + 1 === data.urls.length}
                icon={<ArrowRightIcon />}
                onClick={() => {
                  if (selectedAttachmentIndex + 1 === data.urls.length) return;
                  setSelectedAttachmentIndex(selectedAttachmentIndex + 1);
                }}
                aria-label="Neste vedlegg"
              />
            </HStack>
            {isCurrentAttachmentImage ? (
              <img
                aria-label="Utlegg vedlegg"
                src={data.urls[selectedAttachmentIndex].signedUrl}
                width="100%"
                height="800"
              />
            ) : (
              <object
                aria-label="Utlegg vedlegg"
                data={data.urls[selectedAttachmentIndex].signedUrl}
                width="100%"
                height="800"
              />
            )}
          </Box>
        )}
      </Stack>
    </VStack>
  );
}

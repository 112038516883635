import {
  Text,
  Box,
  CardBody,
  Stack,
  CardHeader,
  Heading,
  Card,
  StackDivider,
} from "@chakra-ui/react";

export function DetailSection({
  details,
  heading,
}: {
  details: ({ title: string; value: string } | null)[];
  heading: string;
}) {
  return (
    <Card w={{ base: "100%" }}>
      <CardHeader>
        <Heading size="xs">{heading}</Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="2">
          {details.map((detail) => {
            if (detail == null) return null;
            return (
              <Box key={detail.title}>
                <Text as="b">{detail.title}</Text>
                <Text pt="2" fontSize="sm">
                  {detail.value}
                </Text>
              </Box>
            );
          })}
        </Stack>
      </CardBody>
    </Card>
  );
}

import {
  Center,
  IconButton,
  Spinner,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { MidLoadingSpinner } from "components";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileMonth } from "../components/MobileMonth";
import { trpc } from "utils/trpc";
import { links } from "@timetable/server/src/utils/Links";

export function MyHoursMobile() {
  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.timesheetRouter.getMobileMonth.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        initialCursor: 1,
      },
    );
  const toast = useToast();
  const navigate = useNavigate();

  if (isLoading) {
    return <MidLoadingSpinner />;
  }

  if (!data) {
    toast({
      title: "Kunne ikke hente data",
      status: "warning",
    });
    return null;
  }

  return (
    <>
      <IconButton
        position="fixed"
        bottom="4"
        right="4"
        zIndex="9999"
        isRound={true}
        size={"2xl"}
        variant="solid"
        aria-label="Done"
        fontSize="20px"
        icon={<FiPlus />}
        onClick={() => navigate(links.timesheetNew)}
      />

      <InfiniteScroll
        hasMore={hasNextPage}
        next={fetchNextPage}
        loader={
          <Center py={5}>
            <Spinner></Spinner>
          </Center>
        }
        dataLength={data.pages.length}
        endMessage={
          <Center py={10}>
            <Text>Ingen flere timeføringer å vise!</Text>
          </Center>
        }
      >
        <VStack mx={3}>
          {data.pages.map((page) => {
            return page.data.map((month) => (
              <MobileMonth month={month} key={month.id} />
            ));
          })}
        </VStack>
      </InfiniteScroll>
    </>
  );
}

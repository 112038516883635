import {
  Thead,
  Tr,
  Th,
  HStack,
  Text,
  Table,
  Tbody,
  Td,
  TableContainer,
  Tfoot,
} from "@chakra-ui/react";
import { Table as TanTable, flexRender } from "@tanstack/react-table";
import { FaSortDown, FaSortUp } from "react-icons/fa";

interface HeaderWithSortingProps {
  table: TanTable<any>;
  emptyTableMessage: string;
}

export const StandardTable: React.FC<HeaderWithSortingProps> = ({
  table,
  emptyTableMessage,
}) => {
  return (
    <TableContainer w="100%">
      {table.getRowModel().rows.length === 0 ? (
        <p>{emptyTableMessage}</p>
      ) : (
        <Table size={{ base: "sm", md: "md" }}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <HStack
                          justifyContent={"flex-start"}
                          cursor={
                            header.column.getCanSort() ? "pointer" : "default"
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Text>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Text>
                          {{
                            asc: <FaSortUp />,
                            desc: <FaSortDown />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </HStack>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} wordBreak={"break-all"}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((footer) => (
                  <Th key={footer.id}>
                    {flexRender(
                      footer.column.columnDef.footer,
                      footer.getContext(),
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      )}
    </TableContainer>
  );
};

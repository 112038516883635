import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  Text,
  StackDivider,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { formatter } from "lib";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { getDoubleTimeString } from "utils";
import { formatHours } from "utils/formatHours";
import { trpc } from "utils/trpc";
import { useReactToPrint } from "react-to-print";

export function EntryDetailPage() {
  const { entry_id } = useParams();

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  if (!entry_id) {
    throw new Error("This page needs a entry_id to be defined.");
  }

  const { data: entry } = trpc.adminRouter.getUserEntryDetail.useQuery({
    entry_id: entry_id,
  });

  if (!entry) return null;

  const data_transformed = [
    {
      title: "Person",
      value: `${entry.employee?.first_name} ${entry.employee?.last_name} (${entry.employee?.email})`,
    },
    {
      title: "Bedrift",
      value: entry.name,
    },
    {
      title: "Dato",
      value: entry?.fromdate.toLocaleDateString("nb-NO"),
    },
    {
      title: "Tidsrom",
      value: `${getDoubleTimeString(entry.fromdate, entry.todate)}`,
    },
    {
      title: "Beskrivelse",
      value: entry?.description,
    },
    entry.project_name && {
      title: "Prosjekt",
      value: entry.project_name,
    },
    {
      title: "Antall timer",
      value: `${formatHours(entry.totalhours == null ? 0 : entry.totalhours)} (${entry.totalhours})`,
    },
    entry.entry_pay && {
      title: "Brutto",
      value: formatter.format(entry.entry_pay == null ? 0 : entry.entry_pay),
    },
    entry.entry_hourly_rate && {
      title: "Sats",
      value: formatter.format(
        entry.entry_hourly_rate == null ? 0 : entry.entry_hourly_rate,
      ),
    },
    entry.entry_pay_valid_from && {
      title: "Sats gyldig fra",
      value: entry.entry_pay_valid_from?.toLocaleDateString("nb-NO") ?? "",
    },
    {
      title: "Transaksjonsid #",
      value: entry.entryid,
    },
  ];
  return (
    <Container ref={contentRef} mt={5}>
      <Card>
        <CardHeader>
          <HStack w={"full"} justifyContent={"space-between"}>
            <Heading size={"sm"}>Timeføringsdetaljer</Heading>
            <IconButton
              icon={<DownloadIcon />}
              aria-label="Last ned transaksjonsdetaljer."
              onClick={() => reactToPrintFn()}
            />
          </HStack>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />}>
            {data_transformed.map(
              (dd) =>
                dd && (
                  <Box>
                    <Text as="b">{dd.title}</Text>
                    <Text>{dd.value}</Text>
                  </Box>
                ),
            )}
          </Stack>
        </CardBody>
      </Card>
    </Container>
  );
}
